import styled, { css } from 'styled-components';

import { CmsApiStickyHeaderLinkData } from 'modules/cms/api/types/blocks/CmsApiStickyHeaderLinksBlock';
import { cssBreakpoints } from 'theme/theme';

import { CmsStickyHeaderLinksItem } from './CmsStickyHeaderLinksItem';

export const CmsStickyHeaderLinksList = styled(
  ({
    className,
    links,
    isSticky,
  }: {
    className?: string;
    links: CmsApiStickyHeaderLinkData[];
    isSticky?: boolean;
  }) => (
    <nav className={className}>
      <ul>
        {links.map((link, index) => (
          <CmsStickyHeaderLinksItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${link.url}`}
            link={link}
            isSticky={Boolean(isSticky)}
          />
        ))}
      </ul>
    </nav>
  ),
)<{ $hasButton?: boolean }>`
  width: 100%;

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    ul {
      flex-direction: row;
    }
  }

  ${(props) =>
    props.isSticky &&
    css`
      display: none;

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        display: flex;
        justify-content: center;
        padding-right: ${props.$hasButton ? 0 : 160}px;
      }
    `};
`;
