import { ReactNode, useRef } from 'react';

import { Box } from 'components/Box/Box';
import { Hide } from 'components/Hide/Hide';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { H5 } from 'components/Text/H5';
import { useSticky } from 'hooks/viewport/useSticky';
import { CmsApiStickyHeaderLinkData } from 'modules/cms/api/types/blocks/CmsApiStickyHeaderLinksBlock';
import { colors, gutterWidth } from 'theme/theme';

import { CmsStickyHeaderLinksContainer } from './CmsStickyHeaderLinksContainer';
import { CmsStickyHeaderLinksList } from './CmsStickyHeaderLinksList';

type Props = {
  links: Array<CmsApiStickyHeaderLinkData>;
  button?: ReactNode;
};

export function CmsStickyHeaderLinks({ links, button }: Props) {
  const ref = useRef<HTMLDivElement>();

  // @ts-expect-error TS(2322): Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
  const sticky = useSticky({ ref, onlyIfScrolledPast: true });

  const hasButton = typeof button !== 'undefined';

  return (
    <>
      <Box boxShadow="0 2px 6px 0 rgba(0, 0, 0, 0.075)">
        <Hide lg xl>
          <Box mb="12px" pt="12px">
            <H5 color={colors.lightContentGrey} textAlign="center">
              On this page
            </H5>
          </Box>
        </Hide>

        <CmsStickyHeaderLinksList links={links} />
      </Box>

      <div
        // @ts-expect-error TS(2322): Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
        ref={ref}
      >
        <Hide xs sm md>
          <CmsStickyHeaderLinksContainer
            $isSticky={sticky}
            py={14}
            px={gutterWidth / 2}
          >
            <a
              href="/"
              aria-label={getText('Idealist')}
              style={{ flex: '0 0 auto' }}
            >
              <div style={{ lineHeight: 0 }}>
                <IdealistLogoOnDemand withText height={49} />
              </div>
            </a>

            <CmsStickyHeaderLinksList
              links={links}
              isSticky
              $hasButton={hasButton}
            />

            {hasButton && (
              <Box display="flex" minWidth={160} justifyContent="flex-end">
                {button}
              </Box>
            )}
          </CmsStickyHeaderLinksContainer>
        </Hide>
      </div>
    </>
  );
}
