import { RefObject, useCallback, useState } from 'react';

import { useInViewportEffect } from './useInViewportEffect';

type Props<T extends HTMLElement> = {
  ref: RefObject<T>;
  offset?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  };
  onlyIfScrolledPast?: boolean;
};

export function useSticky<T extends HTMLElement>({
  ref,
  offset,
  onlyIfScrolledPast,
}: Props<T>): boolean {
  const [sticky, setSticky] = useState(false);

  useInViewportEffect({
    ref,
    offset,
    callback: useCallback(
      ({ inViewport, hasScrolledPast }) => {
        const scrolledPastRuleFulfilled =
          !onlyIfScrolledPast || hasScrolledPast;

        setSticky(!inViewport && scrolledPastRuleFulfilled);
      },
      [onlyIfScrolledPast],
    ),
  });

  return sticky;
}
