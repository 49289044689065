import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { colors } from 'theme/theme';

export const CmsStickyHeaderLinksContainer = styled(Box)<{
  $isSticky: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 7.5%);
  transition: transform 0.45s ease;
  transform: ${(props) =>
    props.$isSticky ? 'translateY(0)' : 'translateY(-100%) translateY(-8px)'};
`;
